<template>
  <div class="join">
    <div class="banner" :style="{ backgroundImage: `url(${bannerUrl})` }">
      <div class="banner-box">
        <div class="banner-title">加入我们</div>
        <div class="title-eng">Joining&nbsp;Us</div>
        <div class="banner-msg">
          加入一家有温度的公司，为千家万户创造有温度的智能空间。
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-box">
        <div class="hand">
          <div class="title-msg">成为家人&nbsp;享受家的温暖</div>
          <div class="p-msg">
            高度智能化的办公区，轻松愉悦的氛围，让你的工作更富创造性。
          </div>
        </div>
        <div class="body-list">
          <div class="body-item">
            <div class="img-title">
              <img height="80px" referrerpolicy="no-referrer" src="../assets/images/joinus_legal@2x.png" />
            </div>
            <div class="title">薪资福利</div>
            <span>
              五险一金、餐费补贴、电脑补贴、<br />
              带薪年假
            </span>
          </div>
          <div class="body-item">
            <div class="img-title">
              <img height="80px" referrerpolicy="no-referrer" src="../assets/images/joinus_care@2x.png" />
            </div>
            <div class="title">关怀福利</div>
            <span>
              结婚礼金、生子礼金、生日福利、<br />
              节假日福利、圣诞活动及丰富多彩<br />的员工小活动
            </span>
          </div>
          <div class="body-item">
            <div class="img-title">
              <img height="80px" referrerpolicy="no-referrer" src="../assets/images/joinus_occupation@2x.png" />
            </div>
            <div class="title">活动福利</div>
            <span>
              下午茶福利、生日会、团建or旅游、<br />
              周年庆、企业文化小活动、年会
            </span>
          </div>
          <div class="body-item">
            <div class="img-title">
              <img height="80px" referrerpolicy="no-referrer" src="../assets/images/joinus_stimulate@2x.png" />
            </div>
            <div class="title">激励福利</div>
            <span> 绩效奖金、年终专项奖励 </span>
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <div class="top-box">
        <div class="box">
          <div class="txt-box">
            <div class="tb">
              <img class="img" src="../assets/images/joinus_add@2x.png" alt="" />
              <p class="tx1">公司地址</p>
            </div>
            <div class="tx2">
              宁波市高新区甬江大道2560号新材料国际创新中心B1-12F
            </div>
            <div class="tb">
              <img class="img" src="../assets/images/joinus_web@2x.png" alt="" />
              <p class="tx1">公司网址</p>
            </div>
            <div class="tx2">www.lingluzn.com</div>
            <div class="tb">
              <img class="img" src="../assets/images/joinus_e-mail@2x.png" alt="" />
              <p class="tx1">招聘邮箱</p>
            </div>
            <div class="tx2">zhaopin@lingluzn.com</div>
            <div class="tb">
              <img class="img" src="../assets/images/joinus_phone@2x.png" alt="" />
              <p class="tx1">招聘电话</p>
            </div>
            <div class="tx2">4008 168 720</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgsUrl from '../utils/imgAddress';

export default {
  data() {
    return{
      bannerUrl: imgsUrl.bannerMd.joinBanner
    }

  }
}
</script>
<style scoped lang="scss">
.join {
  .banner {
    width: 100%;
    height: 900px;
    background-size: cover;
    background-position: top;
    background-position-x: center;
    background-position-y: top;
    position: relative;
    letter-spacing: 5px;

    .banner-box {
      position: absolute;
      top: 30%;
      left: 10%;
      color: #d8d8d8;

      .banner-title {
        width: 220px;
        font-size: 48px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
      }

      .title-eng {
        width: 343px;
        font-size: 48px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
        text-align: left;
      }

      .banner-msg {
        letter-spacing: 2px;
        color: #999999;
        font-size: 22px;
      }
    }
  }

  .main {
    height: auto;
    margin: 120px 0;
    display: flex;
    justify-content: center;

    .main-box {
      width: 1400px;

      .hand {
        letter-spacing: 3px;

        .title-msg {
          height: 50px;
          display: block;
          text-align: center;
          font-size: 30px;
          color: #fff;
          margin-bottom: 14px;
          letter-spacing: 4px;
        }

        .p-msg {
          font-size: 20px;
          color: rgba(128, 128, 128, 1);
          line-height: 35px;
          // text-indent: 40px;
          text-align: center;
          padding-bottom: 100px;
        }
      }

      .body-list {
        display: flex;
        justify-content: space-around;
        padding-bottom: 32px;
        text-align: center;
        color: #fff;

        .body-item {
          margin-top: 40px;

          .title {
            font-size: 20px;
            margin-bottom: 40px;
          }

          span {
            font-size: 16px;
            line-height: 24px;
            color: rgba(153, 153, 153, 1);
          }
        }
      }
    }
  }

  .map {
    min-height: 650px;
    background: url("../assets/images/joinus_ plat@2x.png");
    background-size: 100% 100%;

    .top-box {
      display: flex;
      justify-content: end;

      .box {
        width: 37%;
        height: 650px;
        align-items: flex-end;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        .txt-box {
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .tb {
            margin: 8% 0 0 0; //间距
            height: 60px;
            display: flex;
            justify-content: left;
            align-items: center;

            .img {
              height: 30px;
              widows: 30px;
            }

            .tx1 {
              font-size: 19px;
              letter-spacing: 2px;
              color: #ffffffbe;
              margin: 12px;
            }
          }

          .tx2 {
            font-size: 16px;
            color: #ffffffbe;
          }
        }
      }
    }
  }
}
</style>
